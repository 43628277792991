import Modal from 'components/misc/Modal';
import { useEffect, useState } from 'react';
import useConsentContext from 'hooks/useConsentContext';
import { getLocalizedPath } from 'utils/localizedRoutes';
import { Trans } from '@lingui/macro';
import BodyText from 'components/misc/BodyText';

/**
 * Implements a modal asking for tracking consent.
 */
const ConsentModal = () => {
  const consentContext = useConsentContext();
  // Default to open if the user has not given, nor denied consent.
  const [isOpen, setIsOpen] = useState(consentContext.hasPendingAction);

  useEffect(() => {
    // Allow reopening of the modal if the consent has been reset.
    setIsOpen(consentContext.hasPendingAction);
  }, [consentContext.hasPendingAction]);

  /**
   * Handles clicks on the deny and close button.
   */
  const handleDeny = () => {
    consentContext.denyAll();
    setIsOpen(false);
  };

  /**
   * Handles clicks on the accept button.
   */
  const handleAccept = () => {
    consentContext.acceptAll();
    setIsOpen(false);
  };

  if (isOpen) {
    return (
      // The modal should not respond to outside clicks to prevent
      // accidental closing (and thus denial).
      <Modal closeTrigger={handleDeny} disableClick={true}>
        <BodyText>
          <p>
            <Trans>
              We use cookies to ensure the functionality of our website and to
              analyse our traffic. Functional cookies are always enabled. Please
              choose whether you would like to accept or deny the analytical
              cookies. Further information can be found in our{' '}
              <a href={getLocalizedPath('/privacy-policy')}>privacy policy</a>.
            </Trans>
          </p>
          <p className="actions--outer">
            <button className="btn btn-yellow" onClick={handleAccept}>
              <Trans>Accept</Trans>
            </button>
            <button className="btn btn-yellow" onClick={handleDeny}>
              <Trans>Deny</Trans>
            </button>
          </p>
        </BodyText>
      </Modal>
    );
  }

  return null;
};

export default ConsentModal;
