import { parse } from 'qs';

/**
 * Gets the active database from the filters array.
 *
 * @param filters
 *   The filters array as fetched from the search query.
 * @returns {string}
 *   The currently selected database.
 */
export default function getDbFromFilters(filters) {
  if (!Array.isArray(filters)) {
    return 'all';
  }
  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i];
    if (filter.field === 'db' && Array.isArray(filter.values)) {
      return filter.values[0];
    }
  }
}

/**
 * Gets the database index from uri.
 *
 * @param uri
 *   The uri or id to get the database index from.
 *
 * @returns {string}
 *   The database index. Prefixed with 'rkd'. See search config 'name'.
 */
export function getDbIndexFromUri(uri) {
  if (typeof uri === 'undefined') {
    return 'all';
  }

  const url = new URL(uri);
  const pathName = url.pathname;
  const pathParts = pathName.split('/');

  let index = `rkd${pathParts[1]}`;
  // Edge case where the uri part is never
  // the same as search config name.
  if (index === 'rkdimageslite') {
    index = 'rkdimages_lite';
  }

  return index;
}

/**
 * Gets the current active database from filters or search params.
 *
 * @param filters
 *   The filters array as fetched from the search query.
 * @param searchParams
 *   The url search parameters.
 *
 * @returns {string}
 *   The current active database id.
 */
export function getCurrentlyActiveDatabase(filters, searchParams) {
  // Check active filters for the currently active database.
  let defaultDatabase = 'all';
  const databaseFilter = filters.find((filter) => filter.field === 'db');

  if (typeof databaseFilter !== 'undefined') {
    defaultDatabase = databaseFilter['values'][0];
  } else {
    // We may be on a detail page with a passed search query.
    const searchString = parse(searchParams.get('c'));

    const db = getDbFromFilters(searchString.filters ?? []);
    if (db) {
      defaultDatabase = db;
    }
  }

  return defaultDatabase;
}
